import React, { useEffect, useState } from 'react';
import {
  RequestsCard,
  RequestsCardNoRequestsText,
  RequestsCardType,
  StringDateRange,
  initTimeOffCommonUi,
  supportedLocaleFromString,
  SupportedLocales,
} from '@amzn/time-off-common-ui';
import { DateTime } from 'luxon';
import { QueryClient, QueryClientProvider } from 'react-query';
import { WidgetView, WidgetViewProps } from 'src/models/WidgetView';
import { getNoOfDaysRangeFromWidgetInput, NO_OF_REQUESTS_TO_DISPLAY, query } from 'src/constants/TimeOffCommonUi';
import { OutputTimeOffRequest } from '@amzn/taa-rtk-query-client';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { KatalLoggerClient } from 'src/logger/KatalLoggerClient';
import { TOR_ALL_REDIRECT_LINK, TOR_REQUEST_REDIRECT_LINK } from 'src/constants/common';

export interface TimeoffRequestsViewProps extends WidgetViewProps {}

const TimeoffRequestsView: WidgetView = ({ widgetInfo, employeeInfo }: TimeoffRequestsViewProps) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const katalLogger = new KatalLoggerClient();

  useEffect(() => {
    const initializeTimeOff = async () => {
      const supportedLocale = supportedLocaleFromString(employeeInfo.languageLocale) || SupportedLocales.EN_US;
      try {
        await initTimeOffCommonUi({
          locale: supportedLocale,
          queryConfig: {
            taapi: query,
          },
        });
        setIsInitialized(true);
      } catch (error) {
        await katalLogger.error('Failed to initialize TimeOff UI:', new Error(String(error)));
        setIsInitialized(true);
      }
    };

    initializeTimeOff();
  }, []);

  const redirectViewAllRequests = () => {
    window.open(TOR_ALL_REDIRECT_LINK);
  };

  const redirectViewRequest = (timeoffRequest: OutputTimeOffRequest) => {
    window.open(TOR_REQUEST_REDIRECT_LINK(timeoffRequest.timeOffRequestId));
  };

  const upcomingDateRange: StringDateRange = {
    startDate: DateTime.now().toISODate(),
    endDate: DateTime.now()
      .plus({ days: getNoOfDaysRangeFromWidgetInput(widgetInfo.widgetInputs.NoOfDays) })
      .toISODate(),
  };
  const pastDateRange: StringDateRange = {
    startDate: DateTime.now()
      .minus({ days: getNoOfDaysRangeFromWidgetInput(widgetInfo.widgetInputs.NoOfDays) })
      .toISODate(),
    endDate: DateTime.now().toISODate(),
  };

  return (
    <>
      {isInitialized ? (
        <QueryClientProvider client={new QueryClient()}>
          <RequestsCard
            employeeId={employeeInfo.employeeId}
            type={RequestsCardType.UPCOMING}
            emptyCardText={RequestsCardNoRequestsText.NO_UPCOMING_REQUESTS}
            dateRange={upcomingDateRange}
            timeOffRequestOnClick={redirectViewRequest}
            countryCode={employeeInfo.countryCode}
            noOfRequestsToDisplay={NO_OF_REQUESTS_TO_DISPLAY}
            displayViewAllButton={true}
            viewAllRequestsOnClick={redirectViewAllRequests}
            displayViewMoreButton={false}
          />
          <RequestsCard
            employeeId={employeeInfo.employeeId}
            type={RequestsCardType.PAST}
            emptyCardText={RequestsCardNoRequestsText.NO_PAST_REQUESTS}
            dateRange={pastDateRange}
            timeOffRequestOnClick={redirectViewRequest}
            countryCode={employeeInfo.countryCode}
            noOfRequestsToDisplay={NO_OF_REQUESTS_TO_DISPLAY}
            displayViewAllButton={true}
            viewAllRequestsOnClick={redirectViewAllRequests}
            displayViewMoreButton={false}
          />
        </QueryClientProvider>
      ) : (
        <Spinner size={SpinnerSize.Medium} />
      )}
    </>
  );
};

export default TimeoffRequestsView;
