export enum PopstarFeature {
  VIEW_PUNCHES = 'feature_view_punches',
  DELETE_DUPLICATE_PUNCH = 'delete_duplicate_punch',
  DELETE_MPCA_MPCS_PUNCH_FOR_WEB = 'delete_mpca_mpcs_punch_for_web',
  DELETE_MPCA_MPCS_PUNCH_FOR_NATIVE = 'delete_mpca_mpcs_punch_for_native',
  FEATURE_CTK_REPORT = 'feature_ctk_report',
  LIMIT_UNVERIFIED_PUNCH = 'limit_unverified_punch',
  FEATURE_MPCA_MPA_REPORT = 'feature_mpca_mpa_report',
  PUNCH_CORRECTION_STATUS_V1 = 'punch_correction_status_v1',
  SUBMIT_MISSED_PUNCH_V2 = 'AtoZTime:MissPunchV2',
  USE_UNIFIED_PUNCHES_API_FOR_WEB = 'use_unified_punches_api_for_web',
  USE_UNIFIED_PUNCHES_API_FOR_NATIVE = 'use_unified_punches_api_for_native',
  PUNCH_EDIT_FOR_WEB_ENABLED = 'punch_edit_for_web',
  PUNCH_EDIT_FOR_NATIVE_ENABLED = 'punch_edit_for_native',
  SHOW_ROUNDED_PUNCH = 'AtoZTime:ShowRoundedPunch',
  MISSED_PUNCH_CLICKABLE_ROW_FOR_WEB_ENABLED = 'missed_punch_clickable_row_for_web',
  MISSED_PUNCH_CLICKABLE_ROW_FOR_NATIVE_ENABLED = 'missed_punch_clickable_row_for_native',
  POPSTAR_TAA_FEATURES_OVERRIDE = 'popstar_taa_features_enabled',
  FEATURE_PUNCH_EDIT = 'feature_punch_edit',
  ATPLUS_TEMPORAL_ROUTING = 'atplus_temporal_routing',
  USE_PAYCODE_DOMAIN_API = 'use_paycode_domain_api',
  EXTEND_PUNCH_ELIGIBILITY_FOR_WEB = 'timecard_extend_punch_eligibility_for_web',
  EXTEND_PUNCH_ELIGIBILITY_FOR_NATIVE = 'timecard_extend_punch_eligibility_for_native',
  TIMECARD_ONE_CLICK_MANAGER_REVIEW_FEATURE = 'atoz_time_card_1_click_manager_review',
  PENDING_PUNCHES_ON_TIMECARD = 'AtoZTime:AtoZTimecardPendingPunches',
}

export interface Features {
  canViewPunches: boolean;
  canSubmitMissedPunch: boolean;
  isDeleteDupePunchEnabled: boolean;
  isDeleteMpcaMpcsForWebEnabled: boolean;
  isDeleteMpcaMpcsForNativeEnabled: boolean;
  ctkPolicy: boolean;
  hasUnverifiedMissedPunchLimit: boolean;
  isPunchEditForWebEnabled: boolean;
  isPunchEditForNativeEnabled: boolean;
  isMissedPunchClickableRowForWebEnabled: boolean;
  isMissedPunchClickableRowForNativeEnabled: boolean;
  useUnifiedPunchesAPIForWeb: boolean;
  useUnifiedPunchesAPIForNative: boolean;
  showRoundedPunch: boolean;
  isPunchCorrStatusV1Enabled: boolean;
  isSubmitMissedPunchV2Enabled: boolean;
  isATPlusTemporalRoutingEnabled: boolean;
  isExtendPunchEligibilityForNativeEnabled: boolean;
  isExtendPunchEligibilityForWebEnabled: boolean;
  isTimeCardOneClickManagerReviewFeatureEnabled: boolean;
  isPendingPunchesOnTimecardEnabled: boolean;
  isPaycodeDomainAPIEnabled: boolean;
}
