import React from 'react';
import { IconChevronRight } from '@amzn/stencil-react-components/icons';
import { Row } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { ISupportAgentConfig } from 'src/components/Widgets/supportOptions/SupportOptionConfig';
import { LinkCard } from '@amzn/stencil-react-components/card';
const SupportOption = (supportAgentOptions: ISupportAgentConfig) => {
  //TODO: Add API integration with state
  return (
    <LinkCard
      height={'3.5rem'}
      width={'100%'}
      justifyContent="center"
      alignItems="center"
      onClick={supportAgentOptions.onClick}
    >
      <Row
        padding={'6px 10px'}
        alignItems={'center'}
        alignSelf={'stretch'}
        backgroundColor={'#FFFFFFFF'}
        gridGap={'12px'}
        flex={'1 0'}
      >
        <Row alignItems={'center'} gridGap={'12px'} flex={1}>
          {supportAgentOptions.leftIconComponent}
          <Text fontSize={'T300'} fontWeight={'regular'}>
            {supportAgentOptions.textData}
          </Text>
        </Row>
        <IconChevronRight />
      </Row>
    </LinkCard>
  );
};

export default SupportOption;
