import React, { useState } from 'react';
import { PunchFeature, TaaConfigsContextProvider } from '@amzn/atoz-dashboard-taa-components';
import { DateTime } from 'luxon';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PopupDatePicker } from '@amzn/atoz-web/Datepicker';
import { Col } from '@amzn/atoz-web';
import { WidgetView, WidgetViewProps } from 'src/models/WidgetView';
import { getInitialSelectedDateFromWidgetInput } from 'src/utils/DateTimeUtils';
import { getTAAComponentConfig } from 'src/components/Widgets/WidgetUtils';
import { usePopstarFeatures } from 'src/hooks/usePopstarFeatures';

export interface PunchViewProps extends WidgetViewProps {}

const PunchView: WidgetView = ({ employeeInfo, widgetInfo }: PunchViewProps) => {
  const [date, setDate] = useState<DateTime>(
    getInitialSelectedDateFromWidgetInput(widgetInfo.widgetInputs.SelectedDate),
  );

  const {
    canSubmitMissedPunch,
    ctkPolicy,
    isExtendPunchEligibilityForWebEnabled,
    hasUnverifiedMissedPunchLimit,
    isATPlusTemporalRoutingEnabled,
    isDeleteDupePunchEnabled,
    isDeleteMpcaMpcsForNativeEnabled,
    isDeleteMpcaMpcsForWebEnabled,
    isExtendPunchEligibilityForNativeEnabled,
    isMissedPunchClickableRowForNativeEnabled,
    isMissedPunchClickableRowForWebEnabled,
    isPaycodeDomainAPIEnabled,
    isPendingPunchesOnTimecardEnabled,
    isPunchCorrStatusV1Enabled,
    isPunchEditForNativeEnabled,
    isPunchEditForWebEnabled,
    isSubmitMissedPunchV2Enabled,
    isTimeCardOneClickManagerReviewFeatureEnabled,
    showRoundedPunch,
    useUnifiedPunchesAPIForNative,
    useUnifiedPunchesAPIForWeb,
  } = usePopstarFeatures();

  return (
    <Col padding={'0px'} gridGap={'S200'}>
      <PopupDatePicker
        id="punch-view-date-picker"
        labelText=""
        defaultValue={date.toFormat('MM/dd/yyyy')}
        value={date.toFormat('MM/dd/yyyy')}
        onChange={(newDate: string) => {
          setDate(DateTime.fromISO(newDate));
        }}
      />

      <QueryClientProvider client={new QueryClient()}>
        <TaaConfigsContextProvider taaConfigs={getTAAComponentConfig(employeeInfo)}>
          <PunchFeature
            selectedDateTime={date} // Change Selected Time using Context
            militaryTime={false}
            isInTimehubAssistant={true} // Enables styling and other THA related effects
            featureGates={{
              canSubmitMissedPunch,
              ctkPolicy,
              isExtendPunchEligibilityForWebEnabled,
              hasUnverifiedMissedPunchLimit,
              isATPlusTemporalRoutingEnabled,
              isDeleteDupePunchEnabled,
              isDeleteMpcaMpcsForNativeEnabled,
              isDeleteMpcaMpcsForWebEnabled,
              isExtendPunchEligibilityForNativeEnabled,
              isMissedPunchClickableRowForNativeEnabled,
              isMissedPunchClickableRowForWebEnabled,
              isPaycodeDomainAPIEnabled,
              isPendingPunchesOnTimecardEnabled,
              isPunchCorrStatusV1Enabled,
              isPunchEditForNativeEnabled,
              isPunchEditForWebEnabled,
              isSubmitMissedPunchV2Enabled,
              isTimeCardOneClickManagerReviewFeatureEnabled,
              showRoundedPunch,
              useUnifiedPunchesAPIForNative,
              useUnifiedPunchesAPIForWeb,
            }}
          />
        </TaaConfigsContextProvider>
      </QueryClientProvider>
    </Col>
  );
};

export default PunchView;
