import KatalLogger, { Level, LoggerConfig } from '@amzn/katal-logger';
import { DIMENSION, EMFMetricPayload } from 'src/models/Metrics';
import { API_ENDPOINTS, STAGE_TO_TIMEHUB_ASSISTANT_ENDPOINT } from '../constants/Urls';
import { getCurrentStage } from '../constants/StageConfig';

export const LOGGER_ERROR_MESSAGE = 'Error occurred while sending metric';
export const NAMESPACE = 'TimeHubAssistant';

export const katalLoggerConfig: LoggerConfig = {
  url: STAGE_TO_TIMEHUB_ASSISTANT_ENDPOINT[getCurrentStage()] + API_ENDPOINTS.LOG_URL,
  logThreshold: Level.INFO,
  maxLogLineSize: 10000,
  logToConsole: false,
};

export const initKatalLoggerInstance = (config: LoggerConfig): KatalLogger => {
  return new KatalLogger(config);
};

export const createEmfLog = (metricPayload: EMFMetricPayload) => {
  const allDimensions = getDimensionStructure(metricPayload.dimensions);
  const metricsMap: Record<string, any> = {};
  metricPayload.metrics.forEach((metric) => {
    metricsMap[metric.metricName] = metric.metricValue;
  });
  return {
    _aws: {
      Timestamp: Date.now(),
      CloudWatchMetrics: [
        {
          Namespace: metricPayload.namespace || NAMESPACE,
          Dimensions: allDimensions.dimensionNames,
          Metrics: metricPayload.metrics.map((metric) => ({
            Name: metric.metricName,
            Unit: metric.metricUnit,
          })),
        },
      ],
    },
    ...allDimensions.dimensionValues,
    ...metricsMap,
  };
};

const getDimensionStructure = (dimension: ReadonlyArray<DIMENSION>) => {
  const dimensionNames: string[] = [];
  const dimensionValues: Record<string, string> = {};
  dimension.forEach((group) => {
    dimensionNames.push(...Object.keys(group));
    Object.entries(group).forEach(([key, value]) => {
      dimensionValues[key] = value;
    });
  });
  return {
    dimensionNames: [dimensionNames],
    dimensionValues,
  };
};
