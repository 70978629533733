import defaultTheme, { StencilTheme } from '@amzn/stencil-react-theme-default';

const fontSizes = {
  T500: {
    xl: { fontSize: '20px', lineHeight: 1.375 },
    l: { fontSize: '20px', lineHeight: 1.42857 },
    m: { fontSize: '20px', lineHeight: 1.46154 },
    s: { fontSize: '20px', lineHeight: 1.45455 },
  },
};

export const timehubAssistantTheme: StencilTheme = {
  ...defaultTheme,
  font: {
    ...defaultTheme.font,
    primary: {
      ...defaultTheme.font.primary,
      size: {
        ...defaultTheme.font.primary.size,
        ...fontSizes,
      },
    },
    monospace: {
      ...defaultTheme.font.monospace,
      size: {
        ...defaultTheme.font.monospace.size,
        ...fontSizes,
      },
    },
  },
};
