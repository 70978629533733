import { TaaConfigs } from '@amzn/atoz-dashboard-taa-components';
import { getCurrentStage, STAGE } from 'src/constants/StageConfig';

export const reverseProxyApiGatewayUrl: { [key in STAGE]: string } = {
  test: 'https://atoz.integ.amazon.com',
  alpha: 'https://atoz.integ.amazon.com',
  beta: 'https://atoz.integ.amazon.com',
  gamma: 'https://atoz.integ.amazon.com',
  prod: 'https://atoz-apps.amazon.work',
};

export const getTAAComponentConfig = (employeeInfo: EmployeeInfo): TaaConfigs => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const popstarFeatures = window['AtoZContext']?.employee?.enabledFeatures;
  const isReverseProxyEnabled: boolean = popstarFeatures.includes('proxy_migration_atoz_timehub_assistant');
  return {
    clientName: 'AtoZ-TimeHub-Assistant',
    host: isReverseProxyEnabled ? reverseProxyApiGatewayUrl[getCurrentStage()] : undefined,
    associateInfo: {
      employeeId: employeeInfo.employeeId,
      locale: employeeInfo.languageLocale,
      siteId: employeeInfo.buildingCode,
      timeZone: employeeInfo.timeZoneId,
    },
  };
};
