import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { I18nextProvider } from 'react-i18next';
import { WidgetView, WidgetViewProps } from 'src/models/WidgetView';
import './attendanceStyles.css';
import { getTAAComponentConfig } from 'src/components/Widgets/WidgetUtils';
import { AttendanceBalanceComponent, TaaConfigsContextProvider } from '@amzn/atoz-dashboard-taa-components';
import { setupI18nextLanguage } from '@amzn/atoz-dashboard-taa-components/dist/i18n';

// TODO - dist import till time card test cases are fixed

export interface AttendanceBalanceViewProps extends WidgetViewProps {}
const AttendanceBalanceView: WidgetView = ({ employeeInfo }: AttendanceBalanceViewProps) => {
  return (
    <QueryClientProvider client={new QueryClient()}>
      <I18nextProvider i18n={setupI18nextLanguage('en-US')}>
        <TaaConfigsContextProvider taaConfigs={getTAAComponentConfig(employeeInfo)}>
          <AttendanceBalanceComponent employeeId={employeeInfo.employeeId} />
        </TaaConfigsContextProvider>
      </I18nextProvider>
    </QueryClientProvider>
  );
};

export default AttendanceBalanceView;
