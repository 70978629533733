import React, { useState } from 'react';
import {
  BinaryRating,
  BinaryRatingFeedbackCardSelection,
  BinaryRatingFeedbackLayout,
  BinaryRatingOnChange,
  BinaryRatingValue,
  BinaryRatingVariant,
  FeedbackCardSelections,
  FeedbackMediaType,
} from '@amzn/stencil-react-components/feedback';
import { pickDataProps, composeStencilComponent } from '@amzn/stencil-react-components/utils';
import { PropTypes } from '@amzn/stencil-react-desc';

import {
  DEFAULT_CHAT_MESSAGE_FEEDBACK_DISCLAIMER_TEXT,
  DEFAULT_CHAT_MESSAGE_FEEDBACK_LABEL_TEXT,
  DEFAULT_CHAT_MESSAGE_FEEDBACK_MODAL_TITLE,
  DEFAULT_CHAT_MESSAGE_FEEDBACK_NO_DESCRIPTION_TEXT,
  DEFAULT_CHAT_MESSAGE_FEEDBACK_OPTIONAL_CONTENT_FOOTER,
  DEFAULT_CHAT_MESSAGE_FEEDBACK_OPTIONAL_CONTENT_LABEL,
  DEFAULT_CHAT_MESSAGE_FEEDBACK_OPTIONS,
  DEFAULT_CHAT_MESSAGE_FEEDBACK_OPTIONS_TITLE,
} from '@amzn/stencil-react-chat-ui';

// TODO - create our own interface based on stencil
export const CustomMessageFeedback: React.FC<any> = ({
  labelText = DEFAULT_CHAT_MESSAGE_FEEDBACK_LABEL_TEXT,
  feedbackModalImageSrc,
  feedbackModalTitle = DEFAULT_CHAT_MESSAGE_FEEDBACK_MODAL_TITLE,
  feedbackModalDisclaimerText = DEFAULT_CHAT_MESSAGE_FEEDBACK_DISCLAIMER_TEXT,
  feedbackOptionsTitle = DEFAULT_CHAT_MESSAGE_FEEDBACK_OPTIONS_TITLE,
  feedbackOptionalContentLabel = DEFAULT_CHAT_MESSAGE_FEEDBACK_OPTIONAL_CONTENT_LABEL,
  feedbackOptionalContentFooter = DEFAULT_CHAT_MESSAGE_FEEDBACK_OPTIONAL_CONTENT_FOOTER,
  feedbackOptions = DEFAULT_CHAT_MESSAGE_FEEDBACK_OPTIONS,
  noAriaDescription = DEFAULT_CHAT_MESSAGE_FEEDBACK_NO_DESCRIPTION_TEXT,
  showOptionalTextArea = false,
  noAriaLabel,
  yesAriaLabel,
  onChange,
  onFeedbackOptionsChange,
  cancelButtonLabel,
  submitButtonLabel,
  shouldShowModal,
  ...restProps
}) => {
  const [hasErrors, setHasErrors] = useState(false);
  const [rating, setRating] = useState<BinaryRatingValue | null>(null);
  const [selections, setSelections] = useState<FeedbackCardSelections | undefined>(undefined);
  const [shouldShowFeedbackCard, setShouldShowFeedbackCard] = useState(true);

  const handleOnChange = (selectedRating: BinaryRatingValue, feedbackSelections?: FeedbackCardSelections) => {
    const feedbackValue: {
      selectedRating: BinaryRatingValue;
      optionalData: FeedbackCardSelections['cardOptionalData'];
      selectedOptions: number[];
    } = {
      selectedRating,
      optionalData: undefined,
      selectedOptions: [],
    };

    if (feedbackSelections) {
      if (feedbackSelections.cardOptionalData) {
        feedbackValue.optionalData = feedbackSelections.cardOptionalData;
      }

      if (Array.isArray(feedbackSelections.selection) && feedbackSelections.selection.length > 0) {
        feedbackValue.selectedOptions = feedbackSelections.selection;
      }
    }

    setRating(selectedRating);
    onChange(feedbackValue);
  };

  const handleRatingChange: BinaryRatingOnChange = (_, value) => {
    if (value === BinaryRatingValue.Yes) {
      handleOnChange(BinaryRatingValue.Yes);
    }
    if (value === BinaryRatingValue.No) {
      if (shouldShowModal !== undefined && !shouldShowModal) {
        handleOnChange(BinaryRatingValue.No);
      } else {
        setShouldShowFeedbackCard(true);
      }
    }
  };

  const handleCloseFeedback = () => {
    setShouldShowFeedbackCard(false);
    setHasErrors(false);
    setSelections(undefined);
    handleOnChange(BinaryRatingValue.No);
  };

  const handleUpdateCardSelections = (data: BinaryRatingFeedbackCardSelection) => {
    setSelections(data);
    onFeedbackOptionsChange &&
      onFeedbackOptionsChange({
        optionalData: data.cardOptionalData,
        selectedOptions: data.selection,
      });
    if (Array.isArray(data.selection) && data.selection.length > 0) {
      setHasErrors(false);
    }
  };

  const handleSubmit = () => {
    if (
      !selections ||
      !selections.selection ||
      (Array.isArray(selections.selection) && selections.selection.length === 0)
    ) {
      setHasErrors(true);
    } else {
      handleOnChange(BinaryRatingValue.No, selections);
      setShouldShowFeedbackCard(false);
      setHasErrors(false);
      setSelections(undefined);
    }
  };

  const feedbackMediaProp = feedbackModalImageSrc
    ? {
        type: FeedbackMediaType.IMAGE,
        src: feedbackModalImageSrc,
        altText: '',
      }
    : undefined;

  const feedbackComponent = (
    <BinaryRating
      {...pickDataProps(restProps)}
      titleText={labelText}
      variant={BinaryRatingVariant.Inline}
      cancelButtonLabel={cancelButtonLabel}
      submitButtonLabel={submitButtonLabel}
      feedbackModalProps={{
        title: feedbackModalTitle,
        //TODO: Remove ts ignore when Stencil 3.8 is released and consumed
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        disclaimerText: feedbackModalDisclaimerText,
        onClose: handleCloseFeedback,
        media: feedbackMediaProp,
      }}
      feedbackCardProps={{
        title: feedbackOptionsTitle,
        options: feedbackOptions,
        isMultipleChoice: true,
        hasErrors,
        hasOptionalTextArea: showOptionalTextArea,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        cardSelections: selections,
        optionalContentLabel: feedbackOptionalContentLabel,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        //TODO: Remove ts ignore when Stencil 3.8 is released and consumed
        optionalContentFooter: feedbackOptionalContentFooter,
        updateCardSelections: handleUpdateCardSelections,
      }}
      yesLabelText={yesAriaLabel}
      noLabelText={noAriaLabel}
      noDescriptionText={noAriaDescription}
      feedbackLayout={BinaryRatingFeedbackLayout.Modal}
      onCancelClick={handleCloseFeedback}
      onRatingChange={handleRatingChange}
      onSubmit={handleSubmit}
      shouldShowFeedbackCard={shouldShowFeedbackCard}
      value={rating}
    />
  );

  return feedbackComponent;
};

export default composeStencilComponent({
  baseComponent: CustomMessageFeedback,
  displayName: 'ChatMessageFeedback',
  description: 'Binary rating to be used with chat message bubble.',
  propTypes: {
    yesAriaLabel: PropTypes.string.description('Accessible label for Yes button.'),
    noAriaLabel: PropTypes.string.description('Accessible label for No button.'),
    cancelButtonLabel: PropTypes.string.description("Label for the 'Cancel' button."),
    submitButtonLabel: PropTypes.string.description("Label for the 'Submit' button."),
    shouldShowModal: PropTypes.bool.description('Indicates whether the feedback modal should be shown.'),
    noAriaDescription: PropTypes.string.description('Accessible description for No button.'),
    feedbackOptions: PropTypes.arrayOf(PropTypes.string).description(
      'Options to show as answers in the feedback modal.',
    ),
    feedbackOptionsTitle: PropTypes.string.description('Title for the feedback options.'),
    feedbackOptionalContentLabel: PropTypes.string.description('Label for the feedback optional content.'),
    feedbackOptionalContentFooter: PropTypes.string.description('Footer text for the feedback optional content.'),
    feedbackModalTitle: PropTypes.string.description('Title for the feedback modal.'),
    feedbackModalDisclaimerText: PropTypes.string.description(
      'Text for the disclaimer below the feedback modal title.',
    ),
    feedbackModalImageSrc: PropTypes.string.description('Image source to display on the top of feedback modal.'),
    labelText: PropTypes.string.description('Label for the rate button group.'),
    showOptionalTextArea: PropTypes.bool.description('Show optional additional feedback text area in feedback modal.'),
    onChange: PropTypes.func
      .description('Callback called when binary rating value change and ready to submit.')
      .format(`(data:BinaryRatingData) => void`),
    onFeedbackOptionsChange: PropTypes.func
      .description('Callback called when feedback options and optional feedback text are changed.')
      .format(`(data:ChatMessageFeedbackOptionsData) => void`),
  },
});
