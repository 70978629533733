import ConnectChatClient from 'src/client/ConnectChatClient';
import { MessageRequest } from 'src/models/ChatSessionManager';
import { ChatMessage, ChatMessageList, ChatWidgetInfo, Citation, SuggestionOption } from 'src/models/ChatMessage';
import { ChatMessageBubbleType } from '@amzn/stencil-react-chat-ui';
import {
  ChatConnectionDetails,
  ConnectEventParticipantRole,
  ConnectEventType,
  ConnectionStatus,
} from 'src/models/ConnectChatSession';
import { ContentType } from 'src/constants/common';
import { DISPLAY_STRINGS } from 'src/constants/DisplayMessages';
import { WidgetType } from 'src/models/WidgetView';

const MESSAGE_TEXT_PRESENTATION_MAPPING: Record<string, string> = {
  WELCOME_MESSAGE: DISPLAY_STRINGS.WELCOME_CHAT_MESSAGE,
  SHOW_SUPPORTING_OPTIONS_TEXT: DISPLAY_STRINGS.SHOW_SUPPORTING_OPTIONS_TEXT,
  SHOW_FALLBACK_SUPPORT_OPTIONS_TEXT: DISPLAY_STRINGS.SHOW_FALLBACK_SUPPORT_OPTIONS_TEXT,
  SHOW_INITIATED_SUCCESS_CALL_MESSAGE: DISPLAY_STRINGS.SHOW_INITIATED_SUCCESS_CALL_MESSAGE,
  SHOW_ERROR_MESSAGE: DISPLAY_STRINGS.SHOW_ERROR_MESSAGE,
};

const SUB_INTENTS_TO_SUGGESTION_OPTION_MAPPING: Record<string, ReadonlyArray<SuggestionOption>> = {
  APPLY_TIMEOFF: [SuggestionOption.APPLY_TIMEOFF],
  REQUEST_ABSENCE: [SuggestionOption.REQUEST_ABSENCE],
  CORRECT_SCHEDULE: [SuggestionOption.CORRECT_SCHEDULE],
  CORRECT_PUNCHES: [SuggestionOption.CORRECT_PUNCHES],
  SURVEY_LINK: [SuggestionOption.SURVEY_LINK],
  WELCOME_SUGGESTIONS: [
    SuggestionOption.CHECK_UPT_BALANCE_MESSAGE,
    SuggestionOption.CHANGE_PUNCH_MESSAGE,
    SuggestionOption.CHECK_TIME_OFF_BALANCE_MESSAGE,
    SuggestionOption.LATE_FOR_SHIFT_MESSAGE,
    SuggestionOption.PLANNING_TIME_OFF_MESSAGE,
    SuggestionOption.CHECK_UPT_HISTORY_MESSAGE,
  ],
};

// TODO: Define return types as needed and move away from any

class ConnectChatSessionManager {
  private client: ConnectChatClient;
  public contactId: string;
  private participantId: string;
  private onMessageReceiveHandlers: ((message: ChatMessage) => void)[];
  private onSessionEndHandler: (() => void)[];
  private onConnectionEstablishedHandlers: (() => void)[];
  private onConnectionStatusChangeHandlers: ((status: ConnectionStatus) => void)[];
  private connectionStatus: ConnectionStatus;

  constructor(chatConnectionDetails: ChatConnectionDetails) {
    this.client = new ConnectChatClient(chatConnectionDetails, 'us-east-1');
    this.contactId = this.client.getContactId();
    this.participantId = this.client.getParticipantId();
    this.onMessageReceiveHandlers = [];
    this.onConnectionEstablishedHandlers = [];
    this.onConnectionStatusChangeHandlers = [];
    this.onSessionEndHandler = [];
    this.connectionStatus = ConnectionStatus.DISCONNECTED;
  }
  public async sendMessage({ messageData, contentType }: MessageRequest): Promise<ChatMessage> {
    this.setConnectionStatus(ConnectionStatus.SENDING_MESSAGE);

    const chatMessage: ChatMessage = {
      hasError: false,
      message: messageData.text,
      showFeedback: false,
      type: ChatMessageBubbleType.USER,
    };
    try {
      const response = await this.client.sendMessage({
        content: JSON.stringify(messageData),
        contentType: contentType,
      });
      chatMessage.messageId = response.data.Id;
      this.setConnectionStatus(ConnectionStatus.SENT_MESSAGE);
    } catch (e) {
      chatMessage.hasError = true;
      this.setConnectionStatus(ConnectionStatus.CONNECTED);
    }
    return chatMessage;
  }

  public initialiseEventListenerHandlers(
    onMessageReceiveHandler: (message: ChatMessage) => void,
    onStatusChangeHandler: (status: ConnectionStatus) => void,
    onSessionEndHandler: () => void,
  ) {
    this.onMessageReceiveHandlers = [(event) => onMessageReceiveHandler(event)];
    this.onConnectionStatusChangeHandlers = [(event) => onStatusChangeHandler(event)];
    this.onSessionEndHandler = [() => onSessionEndHandler()];
  }

  public onMessage(event: any) {
    // TODO: Verify user sent message and set connection status to awaiting response
    if (isBotConnectionMessageEvent(event.data)) {
      this.setConnectionStatus(ConnectionStatus.CONNECTED);
      return;
    }
    if (isBotConnectionEndMessageEvent(event.data)) {
      this.connectionStatus = ConnectionStatus.DISCONNECTED;
      this.triggerHandlers(event, this.onSessionEndHandler);
      return;
    }
    if (isValidChatBotMessage({ ...event.data })) {
      this.setConnectionStatus(ConnectionStatus.CONNECTED);
      this.triggerHandlers(buildChatMessage({ ...event.data }), this.onMessageReceiveHandlers);
    }
    if (isValidUserSentMessage({ ...event.data })) {
      this.setConnectionStatus(ConnectionStatus.SENT_MESSAGE);
      this.triggerHandlers(buildChatMessage({ ...event.data }), this.onMessageReceiveHandlers);
    }
  }

  public onConnectionEstablished(event: any) {
    this.triggerHandlers(event, this.onConnectionEstablishedHandlers);
  }

  public async initiateChatSession(): Promise<any> {
    this.setConnectionStatus(ConnectionStatus.CONNECTING);
    this.setupEventListeners();
    const response = await this.client.connect();
    this.setConnectionStatus(ConnectionStatus.CONNECTED);
    return response;
  }

  public async endChatSession(): Promise<any> {
    const response = await this.client.disconnect();
    this.setConnectionStatus(ConnectionStatus.DISCONNECTED);
    return response;
  }

  public async getSessionChatTranscript(request?: {
    maxResults: number;
    sortOrder: 'DESCENDING' | 'ASCENDING';
  }): Promise<ChatMessageList> {
    const response = await this.client.getTranscript(request ?? { maxResults: 100, sortOrder: 'ASCENDING' });
    const transcriptMessages = response.data.Transcript;
    return buildTranscriptChatMessages(getValidTranscriptChatMessages(transcriptMessages));
  }

  private setupEventListeners() {
    this.client.onMessageReceive((event) => this.onMessage(event));
    this.client.onConnectionEstablished((event) => this.onConnectionEstablished(event));
  }

  private setConnectionStatus(status: ConnectionStatus) {
    this.connectionStatus = status;
    this.triggerHandlers(status, this.onConnectionStatusChangeHandlers);
  }

  private triggerHandlers(event: any, handlers: any[]) {
    handlers.forEach((handler) => handler(event));
  }
}

const buildTranscriptChatMessages = (transcriptMessages: ReadonlyArray<any>): ChatMessageList => {
  return transcriptMessages.map((message) => buildChatMessage(message));
};

const getValidTranscriptChatMessages = (transcriptMessages: any[]): ChatMessageList => {
  return transcriptMessages.filter((message) => isValidChatBotMessage(message) || isValidUserSentMessage(message));
};

const buildChatMessage = ({ ParticipantRole, Id, Content }: any): ChatMessage => {
  const receivedMessageData = JSON.parse(Content);
  return {
    messageId: Id,
    message: getMessageText(receivedMessageData),
    type:
      ParticipantRole === ConnectEventParticipantRole.CUSTOM_BOT
        ? ChatMessageBubbleType.CHATBOT
        : ChatMessageBubbleType.USER, // considering that all other types have been filtered already
    showFeedback: ParticipantRole === ConnectEventParticipantRole.CUSTOM_BOT,
    hasError: false,
    dataViewType: getDataViewType(receivedMessageData),
    widgetInfo: getWidgetInfo(receivedMessageData),
    suggestionOptions: getSuggestionOptions(receivedMessageData),
    citations: getCitationsInfo(receivedMessageData),
  };
};

const getCitationsInfo = (receivedMessageData: any): Citation[] => {
  return receivedMessageData.citations?.map((citation: any) => {
    return {
      citationId: citation.citation_id,
    };
  });
};

const getMessageText = (receivedMessageData: any): string => {
  if (
    receivedMessageData.messageTextMappingKey &&
    MESSAGE_TEXT_PRESENTATION_MAPPING[receivedMessageData.messageTextMappingKey]
  ) {
    return MESSAGE_TEXT_PRESENTATION_MAPPING[receivedMessageData.messageTextMappingKey];
  }
  return receivedMessageData.text ? receivedMessageData.text.trim() : '';
};

const getSuggestionOptions = (receivedMessageData: any): SuggestionOption[] => {
  if (!receivedMessageData.suggestionItems) {
    return [];
  }
  return receivedMessageData.suggestionItems.flatMap((suggestionItem: string) => getSuggestionOption(suggestionItem));
};

const getSuggestionOption = (suggestionOption: string): ReadonlyArray<SuggestionOption> => {
  if (SUB_INTENTS_TO_SUGGESTION_OPTION_MAPPING[suggestionOption]) {
    return SUB_INTENTS_TO_SUGGESTION_OPTION_MAPPING[suggestionOption];
  }
  const suggestionOptions = [];
  if (Object.keys(SuggestionOption).includes(suggestionOption)) {
    suggestionOptions.push(SuggestionOption[suggestionOption as SuggestionOption]);
  }
  return suggestionOptions;
};

const getDataViewType = (receivedMessageData: any): WidgetType | undefined => {
  if (receivedMessageData.isInvalid) {
    return WidgetType.LIVE_SUPPORT;
  }
  if (receivedMessageData.shouldShowRequestCallBack) {
    return WidgetType.LIVE_SUPPORT_CALL_BACK;
  }
  if (receivedMessageData.showSupportOptions) {
    //TODO: move to switch
    return WidgetType.LIVE_SUPPORT;
  }
  switch (receivedMessageData.dataViewComponent) {
    case 'showUPTBalance':
      return WidgetType.VIEW_UPT_BALANCE;
    case 'showAttendanceHistory':
      return WidgetType.VIEW_UPT_HISTORY;
    case 'showPunches':
      return WidgetType.VIEW_PUNCHES;
    case 'showTimeOffBalance':
      return WidgetType.VIEW_TIMEOFF_BALANCES;
    case 'showTimeoffRequests':
      return WidgetType.VIEW_TIMEOFF_REQUESTS;
    case 'showSchedule':
      return WidgetType.VIEW_SCHEDULE;
    default:
      return receivedMessageData.dataViewComponent;
  }
};

const getWidgetInfo = (receivedMessageData: any): ChatWidgetInfo | undefined => {
  let widgetId: WidgetType | undefined = undefined;
  let widgetInputs = {};
  let widgetApiContext;
  if (receivedMessageData.widgetInfo) {
    widgetId = receivedMessageData.widgetInfo.widget_id;
    widgetInputs = receivedMessageData.widgetInfo.widget_inputs;
    widgetApiContext = receivedMessageData.widgetInfo.widgetApiContext;
  }
  if (receivedMessageData.isInvalid) {
    // TODO: Refine logic for widgetId
    widgetId = WidgetType.LIVE_SUPPORT;
  }
  if (receivedMessageData.shouldShowRequestCallBack) {
    widgetId = WidgetType.LIVE_SUPPORT_CALL_BACK;
  }
  if (receivedMessageData.showSupportOptions) {
    widgetId = WidgetType.LIVE_SUPPORT;
  }
  if (widgetId && widgetInputs) {
    return {
      widgetId,
      widgetInputs,
      apiDataContext: widgetApiContext,
    };
  }
  return undefined;
};

const isValidChatBotMessage = ({ Type, ParticipantRole, ContentType: messageContentType }: any): boolean => {
  return (
    Type === ConnectEventType.MESSAGE &&
    ParticipantRole === ConnectEventParticipantRole.CUSTOM_BOT &&
    messageContentType === ContentType.APPLICATION_JSON
  );
};

const isValidUserSentMessage = ({ Type, ParticipantRole, ContentType: messageContentType, Content }: any): boolean => {
  const isValidUserMessage =
    Type === ConnectEventType.MESSAGE &&
    ParticipantRole === ConnectEventParticipantRole.CUSTOMER &&
    messageContentType === ContentType.APPLICATION_JSON;
  if (!isValidUserMessage) {
    return false;
  }
  const messageContent = JSON.parse(Content);
  const shouldNotDisplayOnUI = messageContent.shouldNotDisplayOnUI == true;
  return !shouldNotDisplayOnUI;
};

const isBotConnectionMessageEvent = ({ Type, ParticipantRole, ContentType: messageContentType }: any): boolean => {
  return (
    Type === ConnectEventType.EVENT &&
    ParticipantRole === ConnectEventParticipantRole.CUSTOM_BOT &&
    messageContentType === 'application/vnd.amazonaws.connect.event.participant.joined'
  );
};

const isBotConnectionEndMessageEvent = ({ Type, ParticipantRole, ContentType: messageContentType }: any): boolean => {
  return (
    Type === ConnectEventType.EVENT &&
    ParticipantRole === 'CUSTOMER' &&
    messageContentType === 'application/vnd.amazonaws.connect.event.participant.left'
  );
};

export default ConnectChatSessionManager;
