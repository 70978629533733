import KatalLogger from '@amzn/katal-logger';
import { EMFMetricPayload } from '../models/Metrics';
import { LOGGER_ERROR_MESSAGE, katalLoggerConfig, initKatalLoggerInstance, createEmfLog } from '../utils/LoggerUtils';
import { TimeHubAssistantLoggerClient } from './TimeHubAssistantLoggerClient';

export class KatalLoggerClient implements TimeHubAssistantLoggerClient {
  private logger: KatalLogger | null = null;

  private async getKatalLogger(): Promise<KatalLogger> {
    if (!this.logger) {
      this.logger = initKatalLoggerInstance(katalLoggerConfig);
    }
    return this.logger;
  }

  async info(message: string, context?: Record<string, unknown>): Promise<void> {
    try {
      await (await this.getKatalLogger()).info(message, context);
    } catch (error: any) {
      console.error(LOGGER_ERROR_MESSAGE);
    }
  }

  async logEMFMetric(metricPayload: EMFMetricPayload, context: Record<string, any>): Promise<void> {
    try {
      const emfLog = createEmfLog(metricPayload);
      await (await this.getKatalLogger()).info('EMF Metric', { emfLog: { ...emfLog, ...context } });
    } catch (error: any) {
      console.error(LOGGER_ERROR_MESSAGE);
    }
  }

  async error(message: string, error: Error, context?: Record<string, unknown>): Promise<void> {
    try {
      await (await this.getKatalLogger()).error(message, { error, context });
    } catch (error: any) {
      console.error(LOGGER_ERROR_MESSAGE);
    }
  }
}
