import { Features, PopstarFeature } from 'src/features/featureTypes';

export const hasFeature = (features: string[], feature: string): boolean => features.includes(feature);

const canSubmitMissedPunch = (features: string[]): boolean =>
  hasFeature(features, PopstarFeature.FEATURE_MPCA_MPA_REPORT) ||
  hasFeature(features, PopstarFeature.FEATURE_CTK_REPORT);

const isPopstarTaaFeaturesOverrideEnabled = (features: string[]): boolean =>
  hasFeature(features, PopstarFeature.POPSTAR_TAA_FEATURES_OVERRIDE);

export const getFeatures = (features: string[]): Features => {
  return {
    canViewPunches: hasFeature(features, PopstarFeature.VIEW_PUNCHES),
    isDeleteDupePunchEnabled: hasFeature(features, PopstarFeature.DELETE_DUPLICATE_PUNCH),
    isDeleteMpcaMpcsForWebEnabled: hasFeature(features, PopstarFeature.DELETE_MPCA_MPCS_PUNCH_FOR_WEB),
    isDeleteMpcaMpcsForNativeEnabled: hasFeature(features, PopstarFeature.DELETE_MPCA_MPCS_PUNCH_FOR_NATIVE),
    ctkPolicy: hasFeature(features, PopstarFeature.FEATURE_CTK_REPORT),
    hasUnverifiedMissedPunchLimit: hasFeature(features, PopstarFeature.LIMIT_UNVERIFIED_PUNCH),
    isPunchEditForWebEnabled:
      canSubmitMissedPunch(features) &&
      (isPopstarTaaFeaturesOverrideEnabled(features)
        ? hasFeature(features, PopstarFeature.PUNCH_EDIT_FOR_WEB_ENABLED) &&
          hasFeature(features, PopstarFeature.FEATURE_PUNCH_EDIT)
        : hasFeature(features, PopstarFeature.PUNCH_EDIT_FOR_WEB_ENABLED)),
    isPunchEditForNativeEnabled:
      canSubmitMissedPunch(features) &&
      (isPopstarTaaFeaturesOverrideEnabled(features)
        ? hasFeature(features, PopstarFeature.PUNCH_EDIT_FOR_WEB_ENABLED) &&
          hasFeature(features, PopstarFeature.PUNCH_EDIT_FOR_NATIVE_ENABLED) &&
          hasFeature(features, PopstarFeature.FEATURE_PUNCH_EDIT)
        : hasFeature(features, PopstarFeature.PUNCH_EDIT_FOR_NATIVE_ENABLED)),
    isMissedPunchClickableRowForWebEnabled: hasFeature(
      features,
      PopstarFeature.MISSED_PUNCH_CLICKABLE_ROW_FOR_WEB_ENABLED,
    ),
    isMissedPunchClickableRowForNativeEnabled: hasFeature(
      features,
      PopstarFeature.MISSED_PUNCH_CLICKABLE_ROW_FOR_NATIVE_ENABLED,
    ),
    useUnifiedPunchesAPIForWeb: hasFeature(features, PopstarFeature.USE_UNIFIED_PUNCHES_API_FOR_WEB),
    useUnifiedPunchesAPIForNative: hasFeature(features, PopstarFeature.USE_UNIFIED_PUNCHES_API_FOR_NATIVE),
    showRoundedPunch: hasFeature(features, PopstarFeature.SHOW_ROUNDED_PUNCH),
    isPunchCorrStatusV1Enabled: hasFeature(features, PopstarFeature.PUNCH_CORRECTION_STATUS_V1),
    isSubmitMissedPunchV2Enabled: hasFeature(features, PopstarFeature.SUBMIT_MISSED_PUNCH_V2),
    isATPlusTemporalRoutingEnabled: hasFeature(features, PopstarFeature.ATPLUS_TEMPORAL_ROUTING),
    isPaycodeDomainAPIEnabled: hasFeature(features, PopstarFeature.USE_PAYCODE_DOMAIN_API),
    isExtendPunchEligibilityForNativeEnabled: hasFeature(features, PopstarFeature.EXTEND_PUNCH_ELIGIBILITY_FOR_NATIVE),
    isExtendPunchEligibilityForWebEnabled: hasFeature(features, PopstarFeature.EXTEND_PUNCH_ELIGIBILITY_FOR_WEB),
    isTimeCardOneClickManagerReviewFeatureEnabled: hasFeature(
      features,
      PopstarFeature.TIMECARD_ONE_CLICK_MANAGER_REVIEW_FEATURE,
    ),
    isPendingPunchesOnTimecardEnabled: hasFeature(features, PopstarFeature.PENDING_PUNCHES_ON_TIMECARD),
    canSubmitMissedPunch: canSubmitMissedPunch(features),
  };
};
