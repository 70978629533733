import { getCurrentStage, STAGE } from 'src/constants/StageConfig';
import Cookies from 'universal-cookie';
import { fetchBaseQuery, BaseQueryApi, FetchArgs } from '@reduxjs/toolkit/query';
import { ATOZ_HOSTS } from 'src/constants/common';

enum AtozTenantId {
  ATOZ_TAAPI_SERVICE = 'TAAPI',
}

const DEFAULT_NO_OF_DAYS_TOR_REQUEST = 15;

export const NO_OF_REQUESTS_TO_DISPLAY = 5;

export const query = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: any) => {
  const stage = getCurrentStage() || STAGE.BETA;
  const baseUrl = ATOZ_HOSTS[stage] + AtozTenantId.ATOZ_TAAPI_SERVICE;

  const baseQuery = fetchBaseQuery({
    baseUrl,
    credentials: 'include',
  });

  if (typeof args !== 'string') {
    // Init request values if not present
    const headers: Record<string, string> = (args.headers as Record<string, string>) || {};
    args.method = args.method || 'GET';
    args.params = args.params || {};

    // // Add custom headers
    headers['Content-Type'] = 'application/json';
    if (stage !== STAGE.PROD) {
      headers['Authorization'] = `Bearer ${new Cookies().get('atoz-oauth-token')}`;
    }
    args.headers = headers;
  }

  // Make the API request
  return baseQuery(args, api, extraOptions);
};

export const getNoOfDaysRangeFromWidgetInput = (noOfDays?: string) => {
  if (noOfDays) {
    return Number(noOfDays);
  }
  return DEFAULT_NO_OF_DAYS_TOR_REQUEST;
};
